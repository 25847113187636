//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
// 查询筛选的年份列表：
export function findScreenYearObj(obj) {
    return request({
        url: '/search/parameters/findScreenYear',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学科列表：
export function findScreenSubjectObj(obj) {
    return request({
        url: '/search/parameters/findScreenSubject',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的年级列表：
export function findScreenGradeObj(obj) {
    return request({
        url: '/search/parameters/findScreenGrade',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学期列表：
export function findScreenSemesterObj(obj) {
    return request({
        url: '/search/parameters/findScreenSemester',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学段列表：
export function findScreenStudySectionObj(obj) {
    return request({
        url: '/search/parameters/findScreenStudySection',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的教材版本列表：
export function findScreenTextbookEditionObj(obj) {
    return request({
        url: '/search/parameters/findScreenTextbookEdition',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的系列：
export function findScreenSeriesObj(obj) {
    return request({
        url: '/search/parameters/findScreenBrand',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询图书列表-试用：
export function getBooksObj(obj) {
    return request({
        url: '/search/tourists/getBooks',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询图书列表-学生：
export function getStuBooksObj(obj) {
    return request({
        url: '/search/tourists/getBooks',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}


// 学历案详情-轮播图和试题：
export function studyXlaObj(obj) {
    return request({
        url: `/search/tourists/queryXlaDetail/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.type}`,    // url = base url + request url
        //url: '/study/resources/studyXla/'&bookId/&folderId,    // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}

// 查询论坛主题列表：
export function TopicListObj(obj,bookId,folderId,id) {
    return request({
        url: `/search/tourists/queryTopicList/${bookId}/${folderId}/${id}`,    // url = base url + request url
        method: 'get',
        data: obj,
    })
}
// 查询论坛主题回复列表：
export function TopicReplyListObj(obj) {
    return request({
        url: `/search/tourists/queryTopicReplyList/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.topicId}`,    // url = base url + request url
        method: 'get',
    })
}

// 学历案详情图片查询：
export function queryXlaImgObj(obj,data) {
    return request({
        url: `/search/tourists/queryXlaImg/${obj.id}`, // url = base url + request url
        method: 'post',
        data: data,
    })
}



// 游客2.0-学历案目录：
export function queryResourceObj(bookId) {

    return request({
        url: `/search/tourists/queryResourceDetailV20/${bookId}`,   // url = base url + request url

        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 游客2.0-学历案目录-知识拓扑：
export function score(data) {
    return request({
        url: `/search/tourists/topology/score`, // url = base url + request url
        method: 'post',
        data: data,
    })
}
